body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dropdown-menu {
    max-height: 370px;
    overflow-y: scroll;
}

.modal-50w {
    max-width: 50% !important;
}

.bg-secondary {
    background-color: #F5F5F5 !important;
}

.table-responsive table {
    table-layout: auto;
    width: 100%;
}

.table-responsive th,
.table-responsive td {
    white-space: nowrap;
    min-width: 100px;
}

.modal-dialog {
    max-width: 800px;
}